import React from 'react'
import css from "../css/Footer.css"
import altimalogo from "../image/ALTIMALOGO.png"
import isologo from "../image/isologo.png"
import isoqr from "../image/isoqr.png"
import { Link } from 'react-router-dom'

import { FaLinkedin } from "react-icons/fa";
import { FaFacebook } from "react-icons/fa";
import { FaInstagramSquare } from "react-icons/fa";
import { FaSquareXTwitter } from "react-icons/fa6";
import { FaRegCopyright } from "react-icons/fa";

const Footer = () => {
  return (
    <>
    <footer>
        <ul>
         <li> <Link to="/" className='footerLink'>Home</Link></li>
         <li> <Link to="/about" className='footerLink'>About</Link></li>
         <li> <Link to="/productssolutions" className='footerLink'>Product and solutions</Link></li>
         <li> <Link to="/service" className='footerLink'>Services</Link></li>
         <li> <Link to="/contact" className='footerLink'>Contact US</Link></li>
        </ul>
        <div className="logo"><img src={altimalogo} alt="" />
        <div className="iso">
            <img src={isologo} alt="" />
            <img src={isoqr} alt="" />

            </div>
        <p>ISO 9001:2015,ISO 27001:2022 & ISO 20000-1:2018 CERTIFIED COMPANY</p>
            </div>
            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3888.8007463133645!2d77.58954147484043!3d12.920524387390207!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xa18a9c4ae092d17%3A0xa917366c1257f762!2sAltima%20Solutions!5e0!3m2!1sen!2sin!4v1715342098412!5m2!1sen!2sin" className='map'  allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
      
    </footer>
    <div className="middlefooter">
      <div className="socialmedia">
        <p>Connect with us on social media</p>
        <div className="mediaicons">
          <a href="https://www.linkedin.com/company/altima-solutions2022" target='_blank'><FaLinkedin className='mediaicon'/></a>
          <a href="#" target='_blank'> <FaFacebook className='mediaicon'/></a>
          <a href="#" target='_blank'> <FaInstagramSquare className='mediaicon'/></a>
          <a href="#" target='_blank'><FaSquareXTwitter className='mediaicon'/></a>
        </div>
      </div>
    </div>
    <div className="copyright">
      <p><FaRegCopyright/>2024 ALTIMA SOLUTION</p>
    </div>
    </>
  )
}

export default Footer
