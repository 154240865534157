import TAIMG from "./image/Talent Acquisition.jpg"
import telecommunicationimg from "./image/Telecommunication.jpg"
import TaleCommunication from "./image/Telecommunication.jpg"
import TA_Brochure from "./image/Altima_TA_Brochure.pdf"

import Survelience from "./image/Products/Survelience.jpg"
import INSTALLAATION from "./image/Solutions/INSTALLAATION.jpg"
import rf from "./image/Solutions/radio frequency.png"
import system from "./image/Solutions/System Intregation.jpg"
import telecommunications from "./image/Products/telecommunications.jpg"

import TA from "./image/Services/TA.jpg"
import cfo from "./image/Services/CFO.jpg"
import chro from "./image/Services/CHRO.jpg"
import mpo from "./image/Services/MPO.jpg"
import IT from "./image/Services/IT.jpg"
import captive from "./image/Services/Captive.jpg"
import lnd from "./image/Services/LnD.jpg"
import rpo from "./image/Services/RPO.jpg"

// TelecommunicationProducts
import fourg from "./image/Products/telecommunications/4G.jpg"
import fiveg from "./image/Products/telecommunications/5G.jpg"
import Hybrid from "./image/Products/telecommunications/Hybrid.jpg"
import LMR from "./image/Products/telecommunications/LMR.jpg"
import satellite_Communication from "./image/Products/telecommunications/satellite Communication.jpg"
// SURVEILLANCE
import bodyCamera from "./image/Products/Survelience/Body Camera.png"

import comingsoon from "./image/coming-soon-stamp.png"



let landPageCards=[
    {image:TAIMG,
        Heading:"Talent Acquisition",
        details:"Altima Solutions was formed in the year 2022 with a mission to cater to the emerging challenges in Talent Acquisition market with a customized approach.",
        file:TA_Brochure,
        id:1,
    },
    {image:telecommunicationimg,
        Heading:"Telecommunication",
        details:"The Telecommunications division at Altima was formed in the year 2023 with a mission to provide the best in class technologywith a customized approach.",
        file:"",
        id:2,
    },
]
const products=[
    
    {
        id:1,
        heading:"TeleCommunication",
        img:telecommunications,
        // link:<Link to="/">Contact us</Link>,
        link:"/",
        arrname:"telecommunicationProducts"
        
    },
    {
        id:2,
        heading:"Survelience",
        img:Survelience,
        // link:<Link to="/">Contact us</Link>,
        link:"/",
        arrname:"Survelience"


    },
]

const solutions=[
    {
        heading:"Installation+Support",
        img:INSTALLAATION,
        // link:<Link to="/">Contact us</Link>,
        link:"/",
        arrname:"installation"

    },
    {
        heading:"System Integrations",
        img:system,
        // link:<Link to="/">Contact us</Link>,
        link:"/",
        arrname:"System_Integrations"

    },
    {
        heading:"RF Solutions",
        img:rf,
        // link:<Link to="/">Contact us</Link>,
        link:"/",
        arrname:"RF_Solutions"
    },
]

const Services=[
    {
        heading:"Talent Acquisition",
        img:TA,
        // link:<Link to="/">Contact us</Link>,
        link:"/",
    },
    {
        heading:"CFO",
        img:cfo,
        // link:<Link to="/">Contact us</Link>,
        link:"/",
    },
    {
        heading:"CHRO",
        img:chro,
        // link:<Link to="/">Contact us</Link>,
        link:"/",
    },
    {
        heading:"MPO",
        img:mpo,
        // link:<Link to="/">Contact us</Link>,
        link:"/",
    },
    {
        heading:"IT",
        img:IT,
        // link:<Link to="/">Contact us</Link>,
        link:"/",
    },
    {
        heading:"Captive",
        img:captive,
        // link:<Link to="/">Contact us</Link>,
        link:"/",
    },
    {
        heading:"L & D",
        img:lnd,
        // link:<Link to="/">Contact us</Link>,
        link:"/",
    },
    {
        heading:"RPO",
        img:rpo,
        // link:<Link to="/">Contact us</Link>,
        link:"/",
    },
]


let telecommunicationProducts=[
    {
        id:1,
        img:LMR,
        name:"LMR",
        arrname:"telecommunicationProducts"
    }, 
    {
        id:2,
        img:fourg,
        name:"4G",
        arrname:"telecommunicationProducts"

    }, 
    {
        id:3,
        img:fiveg,
        name:"5G",
        arrname:"telecommunicationProducts"

    }, 
    {
        id:4,
        img:Hybrid,
        name:"Hybrid",
        arrname:"telecommunicationProducts"

    },
    {
        id:5,
        img:satellite_Communication,
        name:"Satellite",
        arrname:"telecommunicationProducts"

    },
]
const Survelienceproducts=[
    {
        id:1,
        img:bodyCamera,
        name:"Body Camera",
        arrname:"Survelienceproducts"

    },
    {
        id:2,
        img:comingsoon,
        name:"Coming soon",
        arrname:"Survelienceproducts"

    },

]
let RF_Solutions=[
    {
        id:1,
        img:comingsoon,
        name:"SURVEY & DESIGN",
        arrname:"RF_Solutions"

    },
    {
        id:1,
        img:comingsoon,
        name:"MCPIT",
        arrname:"RF_Solutions"

    },
    {
        id:1,
        img:comingsoon,
        name:"POC",
        arrname:"RF_Solutions"

    },
    {
        id:1,
        img:comingsoon,
        name:"MESH RADIOS",
        arrname:"RF_Solutions"

    },
    {
        id:1,
        img:comingsoon,
        name:"IP LINKS",
        arrname:"RF_Solutions"

    },
]
let installation=[
    {
        id:1,
        img:comingsoon,
        name:"Coming soon",
        arrname:"installation"

    },
]
let System_Integrations=[
    {
        id:1,
        img:comingsoon,
        name:"Coming soon",
        arrname:"System_Integrations"

    },
]
export {landPageCards,products,solutions,Services,telecommunicationProducts,Survelienceproducts,RF_Solutions,installation,System_Integrations}