import React from 'react'
import css from "./css/sliderDetails.css"
import innerpageimg1 from "./img/innerpageimg1.jpg"

const TA = () => {
  return (
    <div className='sliderDetails'>
      <img src={innerpageimg1} alt="" className='taimg'/>
      <p><h1>Interview as a service</h1>
        Altima offers a specialized video interview platform with a pool of expert interviewers to make hiring more accessible and faster. Our expert panels use job specifications to evaluate candidates and match them to the organization's needs. We use a mix of technology and skilled people to find the perfect fit</p>
   
    </div>
  )
}

export default TA
