import React from 'react'
import innerpageimg2 from "./img/innerpageimg2.png"
import css from "./css/sliderDetails.css"
import { GoDotFill } from "react-icons/go";
const PortableLTE = () => {
  return (
    <div className='sliderDetails'>
    <img src={innerpageimg2} alt=""  className='lteimg'/>
  
    <p><h1>Ultra Portable LTE 4G/5G/systems</h1>
    <ul>
      <li><GoDotFill/> LTE operation in Private Networks on the Move.</li>
      <li><GoDotFill/> Private Android Application for voice and message. CAML App developed for End USERS on their Mobile Phones</li>
      <li><GoDotFill/> Standard LTE Bands with paired Phone devices</li>
      <li><GoDotFill/>  3GPP frequency band options from 650 MHz to 3.8GHz supporting both FDD and TDD</li>
      <li><GoDotFill/> Supports 2 x 2 MIMO</li>
      <li><GoDotFill/> High Performance frequency Stability as well as GPS support</li>
    </ul>
    </p>

  </div>
  )
}

export default PortableLTE
