import React, { useState, useEffect, useRef } from "react";
// import emailjs from "@emailjs/browser";
import css from "../css/contact.css"
import final from "../image/final.png"

import AOS from 'aos';
import 'aos/dist/aos.css';


export default function Contact() {
  useEffect(() => {
    AOS.init();
  }, [])
  let validation = () => {

  }
  const [errors, setErrors] = useState({})
  const [result, setResult] = React.useState("");
  let [details, setDetails] = useState({
    name: "",
    email: "",
    mobile: "",
    query: "",
  });

  const detail =useRef({
    name: "",
    email: "",
    mobile: "",
    query: "",
  })
  
  let handleChange = (e) => {
    let { name, value } = e.target;
    setDetails((prev) => {
      return { ...prev, [name]: value };
    });
  };

  let handleSubmit = async (e) => {
    e.preventDefault();
    e.target.value=""
    console.log(details);
    console.log(details.email);
    console.log(details.mobile.length);

    const validationErrors = {}

    if (!details.name.trim()) {
      validationErrors.name = "username is required"
    }

    if (!details.email.trim()) {
      validationErrors.email = "email is required"
    } else if (!/\S+@\S+\.\S+/.test(details.email)) {
      validationErrors.email = "email is not valid"
    }

    // if (typeof(details.mobile)==String) {
    //   validationErrors.mobile = "Mobile number should be min 10 character"
    // }
    if(details.mobile.length<10){
      validationErrors.mobile = "Mobile number should be min 10 character"
    }

    if (!details.query.trim()) {
      validationErrors.query = "Enter your query"
    }
    setErrors(validationErrors)

    if (Object.keys(validationErrors).length === 0) {
      
      
    // let serviceId = "service_bak0kze";
    // let templateID = "template_yovf81x";
    // let publicKey = "l9J2dtMvhqXbgnjhm";

    // let templateParam = {
    //   from_name: details.name,
    //   from_email: details.email,
    //   from_query: details.query,
    //   from_mobile: details.mobile,
    //   from_message: details.query,
    // };
    // emailjs
    //   .send(serviceId, templateID, templateParam, publicKey)
    //   .then((response) => {
    //     console.log("Email sent Successfully", response);
    //   });

    //   alert("Form Submitted successfully");

      // setDetails({
      //   name: "",
      //   email: "",
      //   mobile: "",
      //   query: "",
      // })
      setResult("Sending....");
    const formData = new FormData(e.target);

    formData.append("access_key", "65effa82-fca4-4e6f-8442-5d08d55f1e72");

    const response = await fetch("https://api.web3forms.com/submit", {
      method: "POST",
      body: formData
    });

    const data = await response.json();

    if (data.success) {
      setResult("Form Submitted Successfully");
      e.target.reset();
    } else {
      console.log("Error", data);
      setResult(data.message);
    }
      console.log(e.target.reset());
      
    }
    console.log(errors);
    if(!errors){
      alert("Form Submitted successfully");

    }


  };


  return (
    <div className="contact" >
      <h1>Contact Us</h1>
      <section data-aos="fade-up"
    data-aos-anchor-placement="center-bottom">
        <div className="imgdiv">
        <img src={final} alt="" />

        </div>
        
         <form action="" onSubmit={handleSubmit}>
         {/* <h2>Get in touch</h2> */}
        <div>
          <aside className="div">
        <input
          type="text"
          placeholder="Enter your full name"
          name="name"
          onChange={handleChange}
          ref={detail.name}
        />
        
        {errors.name && <span>{errors.name}</span>}
        </aside>
        <aside>
        <input
          type="email"
          placeholder="Enter your email"
          name="email"
          onChange={handleChange}
          ref={detail.email}
        />
        {errors.email && <span>{errors.email}</span>}
        </aside>
        </div>
        
        <input
          type="number"
          placeholder="Enter your Mobile Number"
          name="mobile"
          onChange={handleChange}
          ref={detail.mobile}
        />
        {errors.mobile && <span>{errors.mobile}</span>}

        <textarea
          id=""
          cols="30"
          rows="10"
          name="query"
          onChange={handleChange}
          placeholder="Write your query"
          ref={detail.query}
        ></textarea>
        {errors.query && <span>{errors.query}</span>}

        <input type="submit" value="Contact us" className="btn" onClick={validation} />
      </form>
      </section>
    </div>
  );
}
