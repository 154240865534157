import React, { useEffect } from 'react'
import aboutTA from "../image/aboutTA.jpg"
import aboutTelecommunication from "../image/aboutTelecommunication.png"
import css from "../css/About.css"
import AOS from 'aos';
import 'aos/dist/aos.css';
export default function About() {
    useEffect(() => {
        AOS.init();
      }, [])
    return (
        <div>
            <div className="aboutBackgroundDiv">
<h1>About Us</h1>
            </div>
            <div className="aboutcard" data-aos="fade-up">
                <img src={aboutTA} alt="" />
                <p> <h1>Talent Acquisition</h1>Altima Solutions was formed in the year 2022 with a mission to cater to the emerging challenges in Talent Acquisition market with a customized approach. Our expert team with a combined experience of more than 25 years in the industry has been successful in attending to all the diverse requirements not just from Talent Acquisition perspective but also other value added services which could optimize the entire process. At Altima, we believe that building a competitive, agile and efficient workforce is key to a Company’s growth story and its success by assisting our Clients to acquire the right Talents from right places at right times in their journey as a valuable partner.</p>
            </div>
            <div className="aboutcard" data-aos="fade-up">
                <p>
                   <h1>Telecommunications</h1> The Telecommunications division at Altima was formed in the year 2023 with a mission to provide the best in class technology. Our team of experts is committed to delivering cutting-edge wireless systems and products, leveraging the latest tehcnologies and industry best practices. We understand our clients’ requirements and tailor our designs to meet their unique needs, ensuring optimal performance and customer satisfaction. We are the leader in Broadband Wireless Systems, specialized in designing systems and products tailored in the RF domain from 50 MHz to 100 GHz along with supporting applications for Voice and Data.
                </p><img src={aboutTelecommunication} alt="" />
            </div>
        </div>
    )
}
