import React, { useEffect } from 'react'
import CarouselPage from './CarouselPage'
import css from "../css/landingpage.css"
import { Link } from 'react-router-dom'
import Contact from './Contact'

import TA from "../image/Talent Acquisition.jpg"
import telecommunication from "../image/Telecommunication.jpg"
import { landPageCards } from '../Data'

import AOS from 'aos';
import 'aos/dist/aos.css';

const LandingPage = () => {
  useEffect(() => {
    AOS.init();
  }, [])
  return (
    <div className='portfolio' data-aos="fade-down">
      <CarouselPage/>
      <h1>PORTFOLIO</h1>
      <div className="landingDivMainCard">
      {landPageCards.map(({image,Heading,details,file,id})=>{
        return(
          
          <div key={id} className='landindDivCard' data-aos="zoom-out-up">
            <div className="cardimgdiv"><img src={image} alt=""/></div>
            
            <h1>{Heading}</h1>
            <p>{details}</p>
            <button><a href={file} target='_blank'>Download Brochure</a></button>
          </div>
          
        )
      })}
      </div>
      <Contact/>
      </div>
  )
}

export default LandingPage
