import logo from './logo.svg';
import './App.css';
import Navbar from './Components/Navbar';
import Footer from './Components/Footer';
import { Route, Routes } from 'react-router-dom';
import LandingPage from './Components/LandingPage';
import Contact from './Components/Contact';
import About from './Components/About';
import CFO from './Components/Detailspage/CFO';
import TA from './Components/Detailspage/TA';
import PortableLTE from './Components/Detailspage/PortableLTE';
import TactilonAgnet from './Components/Detailspage/TactilonAgnet';
import SlideDetails from './Components/SlideDetails';
import ProductsSolutions from './Components/ProductsSolutions';
import Service from './Components/Service';
import { FaArrowUp } from "react-icons/fa6";
import { useState,useEffect } from 'react';

function App() {
  const [backtoTop,setBacktoTop]=useState(false)
 useEffect(()=>{
  if(window.scrollY>100){
    // console.log("sex")
    setBacktoTop()
  }else{
    setBacktoTop(false)
  }
 },[])
 const scrollup=()=>{
window.scrollTo({
  top:0,
  behavior:"smooth"
})
 }
  return (
    <div >
  {backtoTop && (<FaArrowUp className='uparrow' onClick={scrollup}/>)}

     <Navbar/>
     <Routes>
      <Route path='/' element={<LandingPage/>}></Route>
      <Route path="/about" element={<About/>}></Route>
      <Route path="/cfo" element={<CFO/>}></Route>
      <Route path="/ta" element={<TA/>}></Route>
      <Route path="/portablelte" element={<PortableLTE/>}></Route>
      <Route path="/tactilonagnet" element={<TactilonAgnet/>}></Route>
      <Route path='/contact' element={<Contact/>}></Route>
      <Route path='/SlideDetails' element={<SlideDetails/>}></Route>
      <Route path='/productssolutions' element={<ProductsSolutions/>}></Route>
      <Route path='/service' element={<Service/>}></Route>
     </Routes>
     <Footer/>
    </div>
  );
}

export default App;
