import React from 'react'
import innerpageimg3 from "./img/innerpageimg3.jpg"
import css from "./css/sliderDetails.css"

const TactilonAgnet = () => {
  return (
    <div className='sliderDetails agnet'>
    <img src={innerpageimg3} className="LTEimg"alt="" />
    <p><h1>Tactilon Agnet</h1>
    Tactilon Agnet is an end-to-end secure solution with guaranteed service levels and end-to-end lifecycle management. Whether you want reliable group collaboration as a service or you're looking for a true hybrid work environment that capitalizes on your existing PMR infrastructure and smart devices, Tactilon Agnet is the optimum solution. Tactilon Agnet responds to your needs for hardened security and redundancy, as well as easy management and maintenance. Choose Tactilon Agnet in a public or a private cloud as a service (SaaS), or keep it totally isolated from the internet.
    </p>
 
  </div>
  )
}

export default TactilonAgnet
