import React from 'react'
import innerpageimg4 from "./img/innerpageimg4.jpg"
import css from "./css/sliderDetails.css"
import { GoDotFill } from "react-icons/go";

const CFO = () => {
  return (
    <div className='sliderDetails'>
    <img src={innerpageimg4} alt="" className='cfoimg' />
    <p><h1>CFO Services</h1>
    <ul>
      <li><GoDotFill className='detailsicons'/> LTE operation in Private Networks on the Move.</li>
      <li><GoDotFill className='detailsicons'/> Private Android Application for voice and message. CAML App developed for End USERS on their Mobile Phones</li>
      <li><GoDotFill className='detailsicons'/> Standard LTE Bands with paired Phone devices</li>
      <li><GoDotFill className='detailsicons'/>  3GPP frequency band options from 650 MHz to 3.8GHz supporting both FDD and TDD</li>
      <li><GoDotFill className='detailsicons'/> Supports 2 x 2 MIMO</li>
      <li><GoDotFill className='detailsicons'/> High Performance frequency Stability as well as GPS support</li>
    </ul>
    </p>
 
  </div>
  )
}

export default CFO
