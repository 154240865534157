import React from 'react'
import css from "../css/SlideDetails.css"
const SlideDetails = (text) => {
  return (
    <div className='sliderDetails'>
    <h1>{text.text}</h1>
    </div>
  )
}

export default SlideDetails
