import React from 'react'
import "../css/CarouselPage.css"
import { useState, useEffect } from 'react';
import Slide1 from "../image/Slide1.png"
import Slide2 from "../image/Slide2.png"
import Slide3 from "../image/Slide3.png"
import Slide4 from "../image/Slide4.png"
// import Slide5 from "../Image/Slide5.JPG"
import { CiPause1 } from "react-icons/ci";
import { CiPlay1 } from "react-icons/ci";
import { FaAngleRight } from "react-icons/fa";
import { FaAngleLeft } from "react-icons/fa";
import { FaHandPointRight } from "react-icons/fa";
import { Link } from 'react-router-dom';
import SlideDetails from './SlideDetails';
// CarouselPage
let TA = (<div>
  <h1 className=''>INTRODUCING</h1>
  <p className=''>Talent acquisition and value added services</p>
  <Link to="/ta">
  <button className='textbtn ,btn'>More details </button></Link>
  </div>)

let LTE = (<div className='LTE'>
  <h1 className='textboxHeading'>INTRODUCING</h1>
  <p className=''>LAGUNA Portable ultra light LTE 4G-5G systems for Tactical communication</p>

  <Link to="/portablelte">
  <button className='textbtn ,btn'>More details</button></Link>
  {/* <SlideDetails text="Hello This is anirban"></SlideDetails> */}
</div>)

let AGNET = (<div>
  
  <h1 className=' '>INTRODUCING</h1>
  <p className=''>MISSION and BUSINESS Critical Communications Solutions for Public and Private Networks.</p>

  <Link to="/tactilonagnet">
  <button className='textbtn ,btn'>More details</button></Link>
        
</div>)


let CFO =(<div>
  <h1 className='textboxHeading  cfo'>INTRODUCING</h1>
  <p className=''>CFO Services</p>

  <Link to="/cfo">
  <button className='textbtn ,btn'>More details</button></Link>
  
  </div>)

  let RFO=(<section className='RFO'>
    {/* <button className='textbtn'>Click here for more details</button> */}
  </section>)
const CarouselPage = () => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [isPlaying, setIsPlaying] = useState(true);

  const images = [
    Slide1, Slide2, Slide3,Slide4
  ];

  const descriptions = [
    TA,
    LTE,
    AGNET,
    CFO,
  ];

  useEffect(() => {
    let intervalId;
    if (isPlaying) {
      intervalId = setInterval(() => {
        setCurrentSlide((prevSlide) =>
          prevSlide === images.length - 1 ? 0 : prevSlide + 1
        );
      }, 3000);
    }
    return () => clearInterval(intervalId);
  }, [currentSlide, isPlaying, images.length]);

  const handlePlayPause = () => {
    setIsPlaying(!isPlaying);
  };

  const goToNextSlide = () => {
    setCurrentSlide((prevSlide) =>
      prevSlide === images.length - 1 ? 0 : prevSlide + 1
    );
  };

  const goToPrevSlide = () => {
    setCurrentSlide((prevSlide) =>
      prevSlide === 0 ? images.length - 1 : prevSlide - 1
    );
  };

  return (
    <div className='mainSlider'>
      <div className="slider-container">
        <div className="secondarySlider">
          <div className="slider">
            {images.map((image, index) => (
              <div
                key={index}
                className={`slide ${index === currentSlide ? 'active' : ''
                  }`}
                style={{
                  backgroundImage: `url(${image})`,
                  transform: `translateX(${(index - currentSlide) * 100
                    }%)`
                }}
              ></div>

            ))}
        <div className="text-box">{descriptions[currentSlide]}</div>

            <button className="prev" onClick={goToPrevSlide}>
              <FaAngleLeft />
            </button>
            <button className="next" onClick={goToNextSlide}>
              <FaAngleRight />
            </button>

          </div>
          <div className="controls">
            <div className="play-pause" onClick={handlePlayPause}>
              {isPlaying ? <CiPause1 /> : <CiPlay1 />}
            </div>
            <div className="circlediv">
              <div className="circle" onClick={() => { setCurrentSlide(0) }} style={currentSlide === 0 ? { backgroundColor: "#ffffff" } : { color: "" }}></div>
              <div className="circle" onClick={() => { setCurrentSlide(1) }} style={currentSlide === 1 ? { backgroundColor: "#ffffff" } : { color: "" }}></div>
              <div className="circle" onClick={() => { setCurrentSlide(2) }} style={currentSlide === 2 ? { backgroundColor: "#ffffff" } : { color: "" }}></div>
              <div className="circle" onClick={() => { setCurrentSlide(3) }} style={currentSlide === 3 ? { backgroundColor: "#ffffff" } : { color: "" }}></div>
              {/* <div className="circle" onClick={() => { setCurrentSlide(4) }} style={currentSlide === 4 ? { backgroundColor: "#ffffff" } : { color: "" }}></div> */}
            </div>
          </div>
        </div>
      </div>

    </div>
  );
};

export default CarouselPage;