import {Services} from "../Data"
import { Link } from "react-router-dom"
import css from "../css/services.module.css"
import React, { useEffect } from 'react'

// import React from 'react'

const Service = () => {
  useEffect(() => {
    // Update the document title using the browser API
    document.title = `Services`;
  });
  return (
    <div className="service">
      <div className={css.servicebackgrounddiv}>
        <p>Services</p>
      </div>
    <div className={css.mainServicediv}>
      <h1>Technology & Services</h1>
      <div className={css.servicediv}>
        {
          Services.map(({heading,img,link},element)=>{
            return(
              <div className={css.servicecard} data-aos="fade-left">
                <img src={img} alt="" />
                <h1>{heading}</h1>

                <Link to={link} className={css.serviceLink}>Know more</Link>
              </div>
            )
          })
        }
      </div>
      </div>
    </div>
  )
}

export default Service

