import React, { useEffect, useState } from 'react'
import css from "../css/ProductsSolutions.module.css"
import { products, solutions, telecommunicationProducts, Survelienceproducts,RF_Solutions,installation,System_Integrations } from "../Data"
import { Link } from 'react-router-dom'
import productnsolutions from "../image/ideaimage.png"
import { RxCrossCircled } from "react-icons/rx";
const ProductsSolutions = () => {
  let [popup, setPopup] = useState(false)
  let [array, setArray] = useState([])
  useEffect(() => {
    // Update the document title using the browser API
    document.title = `Products and solutions`;

  }, []);

  const handlepopup = () => {
    setPopup(false)
  }

  let show = (arrname) => {
    if (arrname === "telecommunicationProducts") {
      setArray(telecommunicationProducts);
      setPopup(true)
    }
    else if (arrname === "Survelience") {
      setArray(Survelienceproducts);
      setPopup(true)
    }
    else if (arrname === "installation") {
      setArray(installation);
      setPopup(true)
    }
    else if (arrname === "System_Integrations") {
      setArray(System_Integrations);
      setPopup(true)
    }
    else if (arrname === "RF_Solutions") {
      setArray(RF_Solutions);
      setPopup(true)
    }
   

  }
  return (
    <div className={css.mainproductsolutionsdiv}>
      <div className={css.productbackgrounddiv}>
        <p>Products and Solutions</p>
      </div>
      {popup &&
        <div className={css.outertelecommunicationProducts}>
          <RxCrossCircled className={css.productCrossIcon} onClick={handlepopup} />
          <div className={css.telecommunicationProducts}>
            {
              array.map(({ id, img, name }) => {
                return (
                  <div key={id} className={css.telecommunicationProductscard}>
                    <img src={img} alt="" />
                    <p>{name}</p>
                  <Link className={css.productsolutionlink} to="/contact"><button>Contact US</button></Link>

                  </div>
                )
              })
            }
          </div>
        </div>
      }
      <div className={css.mainproductdiv}>
        <h1>Products</h1>

        <div className={css.productdiv}>
          {
            products.map(({ heading, img, id, arrname }, index) => {
              return (
                <div className={css.productcard} data-aos="fade-down-right" key={id}>
                  <div className={css.productcardDiv}>
                    <img src={img} alt="" />
                  </div>
                  <h1>{heading}</h1>
                  <Link className={css.productsolutionlink}><button onClick={() => { show(arrname) }}>Show more products</button></Link>

                </div>
              )
            })
          }
        </div>
      </div>
      <div className={css.mainproductsolutionsdiv}>
        <div className={css.mainproductdiv}>
          <h1>Solutions</h1>
          <div className={css.productdiv}>
            {solutions.map(({ heading, img, link ,arrname }, index) => {
              return (
                <div className={css.solutionCard} data-aos="fade-down-right">
                  <div className={css.solutionCardDiv}>
                    <img src={img} alt="" />
                  </div>
                  <h1>{heading}</h1>
                  <Link className={css.productsolutionlink}><button onClick={() => { show(arrname) }}>Show more products</button></Link>
                </div>
              )
            })}
          </div>
        </div></div>

    </div>
  )
}

export default ProductsSolutions
